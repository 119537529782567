import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef, useState } from "react";
import Loading from "../Loading/Loading";

gsap.registerPlugin(ScrollTrigger);

export default function Hero({ lenis }) {
    const divRef = useRef(null);

    const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Oct","Nov","Dec"];
    const d = new Date();
    const current_month = month[d.getMonth()];
    const current_year = new Date().getFullYear().toString().substr(-2);

    const [isLoading, setIsLoading] = useState(true);
    const [animateLoadingText, setAnimateLoadingText] = useState(false);

    const isMobile = window.innerWidth <= 768;
    useEffect(() => {
        let ctx = gsap.context(() => {

            gsap.fromTo(
                divRef.current,
                {
                    opacity: 1,
                    scale: 1,
                    y: 0,
                },
                {
                    opacity: 0,
                    scale: 0.90,
                    y: isMobile ? '70vh' : "60vw", // Adjust this value to move the div down
                    ease: 'power1.out', // Easing for smooth animatio
                    scrollTrigger: {
                        trigger: divRef.current,
                        start: isMobile ? 'top+=0px' : "top 5px",
                        end: isMobile ? 'bottom-=100px' : "bottom+=100px",
                        scrub: true,
                        toggleActions: 'play none none none'
                        // markers: true, // Optional: remove this line once you confirm it's working
                    },
                }
            );
        });
        return () => ctx.revert(); // <- cleanup!
    }, []);

    // loading area animation
    useEffect(() => {
        if (isLoading) {
            setAnimateLoadingText(true);
            lenis.stop();
        }
        else {
            setAnimateLoadingText(false)
            lenis.start();
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 1500);

        return () => {
            lenis.stop()
        };
    }, [isLoading]);



    return (
        <>

            {/* loading */}
            <Loading isLoading={isLoading} animateLoadingText={animateLoadingText} />
            {/* ---- */}

            <div
                className="h-full pt-20 md:pt-10 lg:pt-4 px-4 mb-[10vh] full-viewport-height relative overflow-hidden z-0 flex-col flex justify-evenly"
                ref={divRef}
            >
                <div className="h-[17vw] md:h-[22vw] lg:h-[22vw] xl:h-[22vw] 2xl:h-[22vw] overflow-hidden relative hidden lg:flex flex-wrap gap-1">
                    <div
                        className={`${isLoading ? "opacity-0 top-60" : "opacity-1 top-0"
                            } absolute left-auto duration-300 ease-in-out delay-500 transition-all text-center w-full`}
                    >
                        <h1
                            className={` font-bold  lg:text-[14vw] xl:text-[17vw] 2xl:text-[18vw] font-Montreal-Bold md:font-Montreal-Regular text-center leading-tight md:leading-normal `}
                        >
                            I'M ANEES
                        </h1>

                    </div>
                </div>

                {/* mobile hero heading */}
                <div className=" flex flex-col flex-wrap gap-1 lg:hidden">
                    <div className="h-[24vw] md:h-[10em] overflow-hidden relative">
                        <div
                            className={`${isLoading ? "opacity-0 top-60" : "opacity-1 top-0"
                                } absolute left-auto duration-700 ease-in-out delay-500 transition-all text-center w-full`}
                        >
                            <h1
                                className={` font-bold text-[20vw] md:text-[7.5rem] font-Montreal-Bold md:font-Montreal-Regular text-center leading-tight md:leading-normal `}
                            >
                                I'M
                            </h1>

                        </div>
                    </div>
                    <div className="h-[24vw] md:h-[10em] overflow-hidden relative">
                        <div
                            className={`${isLoading ? "opacity-0 top-60" : "opacity-1 top-0"
                                } absolute left-auto duration-700 ease-in-out delay-500 transition-all text-center w-full`}
                        >
                            <h1
                                className={` font-bold text-[20vw] md:text-[8rem] font-Montreal-Bold md:font-Montreal-Regular text-center leading-tight md:leading-normal `}
                            >
                                ANEES
                            </h1>

                        </div>
                    </div>
                </div>

                <div className="my-2 md:my-10"></div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                    <div
                        className={`${isLoading ? "opacity-0 " : "opacity-1"
                            }duration-300 ease-in-out delay-[0.6s] transition-all`}
                    >
                        <div className="flex items-center text-portfolio-black-accent">
                            <p className="text-base 2xl:text-xl max-w-96">
                                I help to boost your business and startup with custom,
                                results-driven websites that elevate your digital presence through
                                cutting-edge development and design.
                            </p>
                        </div>
                    </div>
                    <div></div>
                    <div className="flex flex-col justify-center items-start md:items-end right-0 gap-2 text-portfolio-black-accent w-full border-t-[0.5px] border-portfolio-black-accent-dark border-opacity-40 md:border-none">
                        <h1 className={`text-[3vw] md:text-[1.8vw] lg:text-lg font-Montreal-Mono ${isLoading ? "opacity-0 " : "opacity-1"
                            }  duration-300 ease-in-out delay-[0.8s] transition-all`}>
                            AVAILABLE FOR FREELANCE WORK
                        </h1>

                        <h1 className={`text-[8vw] md:text-[10vw] lg:text-[80px] leading-none font-Montreal-Bold font-medium text-portfolio-black-accent-dark ${isLoading ? "opacity-0 " : "opacity-1"
                            }  duration-300 ease-in-out delay-[1s] transition-all`}>
{current_month}'{current_year}
                        </h1>
                    </div>
                </div>
            </div>
        </>
    );
}
